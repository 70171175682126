// hookup.js

import axios from 'axios';
import { hasProperty, getTimeStamp } from './util';

// The hookup function is an asynchronous JavaScript function that sends a GET request to an API endpoint, constructed dynamically based on provided input parameters. The function performs the following main tasks:

//     Input Parameters:
//         Takes an object as an argument, with optional properties: payToken, promoToken, regNumber, vin, regType, and timeout (default set to 15,000 ms).

//     API URL Construction:
//         If payToken is provided, it constructs the apiUrl with the payToken parameter.
//         If promoToken and at least one of regNumber or vin are provided, it hashes the promoToken using the SHA-256 algorithm via window.crypto.subtle.digest. This hash is then included in the apiUrl with additional query parameters (regNumber, vin, regType).
//         If neither condition is met, the function logs an error and exits early without making an API request.

//     HTTP GET Request:
//         Uses axios.get to send a GET request to the constructed apiUrl with a specified timeout.

//     Response Handling:
//         If response.data is null or undefined, a warning is logged.
//         Checks the response for an error property (virhekoodi). If not present, returns response.data as a valid result. If the property is present, still returns response.data to indicate an error in the response.

//     Error Handling:
//         Catches any errors from the HTTP request:
//             If the response status is 401 (Unauthorized), logs a warning, shows an alert, and throws an "Unauthorized access" error.
//             For other errors, logs a general warning with the status code (if available) and throws a generic error message.

//     Logging:
//         Includes timestamped console logs for tracking the flow (some commented out).

// Key Points:

//     The function relies on environment variables (process.env.REACT_APP_BACKEND_ENDPOINT) for the base URL.
//     It uses window.crypto for secure hashing of the promoToken.
//     Handles API responses by checking for potential error indicators within the response data.
//     Provides robust error handling with user alerts and detailed warnings logged in the console.


// Send a request to API
// 
export default async function hookup({ payToken = null, promoToken = null, regNumber = null, vin = null, regType = null, timeout = 15000 } = {}) {

  // console.log(getTimeStamp(), 'hookup begins, payToken', payToken, 'promoToken', promoToken);

  let apiUrl = '';

  if (payToken) {
    apiUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}${'?payToken='}${payToken}`;
  } else if (promoToken && (regNumber || vin)) {
    // Convert the token to an ArrayBuffer
    const encoder = new TextEncoder();
    const data = encoder.encode(promoToken);
    let hashedPromoToken = '';
    try {
      const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      hashedPromoToken = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    } catch (error) {
      // console.error('Error during hashing:', error);
    }

    apiUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}${'?promoToken='}${hashedPromoToken}${'&regNumber='}${regNumber}${'&vin='}${vin}${'&regType='}${regType}`;
  } else {
    console.log(getTimeStamp(), 'hookup, invalid argument or promoToken not OK');
    return;
  }
  // console.log(getTimeStamp(), 'hookup, apiUrl', apiUrl);

  return axios.get(apiUrl, { timeout })
    .then(response => {
      // console.log(getTimeStamp(), 'hookup, about to return response.data after axios.get');
      if (response.data === null || response.data === undefined) {
        console.log(getTimeStamp(), 'hookup, response.data null or undefined');
      }
      // check for content error in the response
      if (!hasProperty(response.data, "virhekoodi")) {
        // errors not found, go ahead and return response     
        // console.log(getTimeStamp(), 'hookup, response.data no error');
        return response.data;
      } else {
        // console.log(getTimeStamp(), 'hookup, response.data error');
        return response.data;
      }
    }) // Return response.data directly
    .catch(error => {
      if (error.response && error.response.status === 401) {
        // Log a general message without revealing API details
        console.warn(getTimeStamp(), 'Unauthorized access attempt');
        alert('Access denied');
        throw new Error('Unauthorized access'); // Throw a new error with a simple message
        // throw new Error('Unauthorized access'); // Throw an error with a message
      } else {
        // console.error('Error fetching data:', error);
        // throw error; // Re-throw other errors
        // For other errors, only log a general message and error status
        console.warn(getTimeStamp(), `Error fetching data: ${error.response?.status || 'Unknown error'}`);
        throw new Error('An error occurred while fetching data');
      }
    });
}

